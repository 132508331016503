import { Component, Vue} from 'nuxt-property-decorator';
import { mapState } from 'vuex';

@Component({
    components: {
        formField: require('~/components/form/form-field/').default,
        validationContainer: require('~/components/form/validation-container/').default,
        btn: require('~/components/form/button/').default,
    },
    computed: mapState(['history', 'user']),
})
export default class LoginFormComponent extends Vue {

    public login: string = null;
    public password: string = null;
    public remember: boolean = true;
    public history: string[];

    public loading = false;

    private head() {
        return {
            title: 'Вход на сайт - Интернет-магазин мебели «ТриЯ»'
        };
    }

    public async submit() {

        if (this.loading || !(this.$refs.validation as any).valid) {
            return;
        }

        try {
            this.loading = true;

            await this.$store.dispatch('UserLogin', {
                login: this.login,
                password: this.password,
                remember: this.remember,
            });

            this.$store.dispatch('SuccessFlash', 'Успешная авторизация');

            if (this.$route.query.backurl && typeof this.$route.query.backurl === 'string') {
                this.$router.push(decodeURI(this.$route.query.backurl));
                
            } else if (this.history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        } catch (error: any) {
            if (!error.handled) {
                this.$store.dispatch('ErrorFlash', 'Неизвестная ошибка')
            }
        } finally {
            this.loading = false;
        }
    }
}
