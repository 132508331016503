import { Component, Vue } from 'nuxt-property-decorator';
import { CreateElement } from 'vue';

@Component
export default class ValidateContainerComponent extends Vue {

    public render(createElement: CreateElement) {
        return createElement('div', this.$slots.default);
    }

    get valid(): boolean {

        let valid = true;
        let firstWrongField: Element = null;

        for (const element of this.$slots.default) {

            if (element.componentInstance && (element.componentInstance as any).test) {
                if (!(element.componentInstance as any).test()) {
                    valid = false;
                    if (!firstWrongField) {
                        firstWrongField = element.componentInstance.$el;
                    }
                }
            }
        }

        if (firstWrongField) {
            firstWrongField.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }

        return valid;
    }
}
